<template>
  <a-layout class="title-breadcrumb">
    <div class="container">
      <a-row type="flex" justify="center" :gutter="[16, 16]">
        <a-col :lg="8">
          <h1 class="title">{{ currentRoute.title }}</h1>
          <a-breadcrumb :separator="'-'" class="breadcrumb">
            <a-breadcrumb-item>
              <router-link :to="{ name: 'home' }">
                <font-awesome-icon :icon="['fa', 'home']" class="anticon" />
                {{ labels.home }}
              </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
              {{ currentRoute.title }}
            </a-breadcrumb-item>
          </a-breadcrumb>
        </a-col>
      </a-row>
    </div>
  </a-layout>
</template>
<script>
import { menus } from "../../../router/client-menus";
import labels from "@/utils/labels";
import { mapGetters } from "vuex";
export default {
  name: "TitleBreadcrumb",
  data() {
    return {
      labels: { ...labels.breadcrumb },
    };
  },
  computed: {
    ...mapGetters("clientAuth", ["getLoggedUser"]),
    loggedUser() {
      return this.getLoggedUser;
    },
    currentRoute() {
      const { accessRoles = [] } = this.loggedUser || {};
      return (
        menus(accessRoles).find((menu) => menu.key === this.$route.name) ||
        menus().find((menu) => menu.key === "home")
      );
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.title-breadcrumb {
  font-family: "Roboto", sans-serif;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(~@/assets/images/client/home-bg.jpg);
  padding-top: 1.5rem;
  padding-bottom: 1.8rem;
  position: relative;
  z-index: 1;
  text-align: center;
  max-height: 133.39px;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: color(c-secondary);
    opacity: 0.65;
    z-index: -1;
  }
  .title {
    color: color(--white);
    font-family: "Exo 2", sans-serif;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    word-break: break-word;
    font-size: 2rem;
  }
  .ant-breadcrumb a {
    color: color(--white) !important;
    transition: color 0.3s;
    text-decoration: none;
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    &:hover {
      color: color(c-hover) !important;
    }
  }

  .ant-breadcrumb > span:last-child {
    color: rgba(255, 255, 255, 0.8) !important;
  }

  .breadcrumb {
    margin-top: 0.9375rem;
    font-size: 1rem;
    .anticon {
      color: color(c-hover);
    }
    span {
      .ant-breadcrumb-separator {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }
}
</style>
