<template>
  <div class="how-to-play-component">
    <a-row type="flex" justify="center" class="header">
      <a-col :xl="13" :lg="15">
        <h2>{{ authenticated ? labels.subtitle2 : labels.subtitle }}</h2>
      </a-col>
    </a-row>
    <a-row type="flex" justify="center" :gutter="[16, 32]">
      <a-col
        v-for="(step, key) in dataSource"
        :key="key"
        :lg="6"
        :sm="12"
        :class="key !== dataSource.length - 1 && 'how-work-item'"
      >
        <div class="how-work-card">
          <div class="how-work-card__step">{{ key + 1 }}</div>
          <h3 class="title">
            <router-link
              :to="{
                name: step.link,
                query: step.query || {},
                params: step.params || {},
              }"
              >{{ step.title }}</router-link
            >
          </h3>
          <p v-if="step.description" class="text--base">
            {{ step.description }}
          </p>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import labels from "@/utils/labels";
export default {
  name: "HowToPlay",
  props: {
    authenticated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labels: labels.howToPlay,
    };
  },
  computed: {
    dataSource() {
      return this.authenticated
        ? this.labels.step.filter((item) => item.link !== "register")
        : this.labels.step;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.how-to-play-component {
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 560px;
  .header {
    margin-bottom: 60px;
    h2 {
      font-family: "Exo 2", sans-serif;
      color: color(--white);
      font-size: 2.25rem;
      font-weight: 500;
      margin: 0;
      line-height: 1.3;
      word-break: break-word;
    }
    p {
      margin: 0;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      font-size: 1rem;
      margin-top: 1rem !important;
      line-height: 1.7;
    }
  }
  .how-work-card {
    text-align: center;
    .text--base {
      color: color(c-hover) !important;
    }
    &__step {
      box-shadow: 0 0 10px color(c-hover-light);
      width: 120px;
      height: 120px;
      background-color: color(c-secondary);
      font-size: 2.25rem;
      display: inline-flex;
      position: relative;
      z-index: 1;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      &::before {
        position: absolute;
        content: "";
        top: -20px;
        right: -90px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: color(c-card);
        z-index: -2;
      }
      &::after {
        position: absolute;
        content: "";
        top: 2px;
        right: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: 50%;
        background-color: transparent;
        border: 6px solid color(c-secondary);
        z-index: -1;
      }
    }
    .title {
      margin: 0;
      margin-top: 1.5rem !important;
      font-family: "Exo 2", sans-serif;
      color: #fff;
      font-weight: 400;
      font-size: 1.5rem;
      word-break: break-word;
      a {
        color: color(--white);
        text-decoration: none;
        &:hover {
          color: color(--white);
          text-decoration: underline;
        }
      }
    }
  }
  .how-work-item {
    @media screen and (min-width: 992px) {
      &::after {
        position: absolute;
        content: "";
        top: 35px;
        right: -25px;
        width: 50px;
        height: 50px;
        background-image: url(~@/assets/images/client/right-arrow.svg);
        background-size: 50px;
        background-repeat: no-repeat;
        opacity: 0.85;
      }
    }
    &:nth-of-type(2n + 2) .how-work-card__step::before {
      top: auto;
      right: auto;
      left: -71px;
      bottom: -62px;
    }
  }
}
</style>
