<template>
  <a-layout class="how-to-play">
    <TitleBreadcrumb />
    <div class="content-base container">
      <HowToPlay :authenticated="authenticated" />
    </div>
  </a-layout>
</template>
<script>
import HowToPlay from "@/components/HowToPlay.vue";
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "../../utils/utils";
import labels from "@/utils/labels";
import { mapState } from "vuex";
export default {
  components: { HowToPlay, TitleBreadcrumb },
  name: "HowToPlayPage",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.howToPlay.title),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.howToPlay.title,
      },
    ],
  },
  computed: {
    ...mapState("clientAuth", ["authenticated"]),
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.how-to-play {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
}
</style>
